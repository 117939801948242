import React from 'react';
import background from './images/background.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="background-image">
        {/* Your content here */}
      </div>
    </div>
  );
}

export default App; 